import { push } from "connected-react-router";
import api from "../../api/api";
import { routePaths, setPathId } from "../../routing/utils-routing-paths";
import { PollingIntervalTypes } from "../../common-interfaces/interfaces";
import { ListInterface } from "../../common-interfaces/lists";
import { fetchCampaigns } from "../../campaigns/redux/campaigns-actions";

// WARNING: Accessing Redux state in an action creator is not
// good practice but is accepted in some cases.
// There are some ways to do it.
// With the way implemented here the payoff is that
// it's not possible to do server-side-rendering.
// https://stackoverflow.com/questions/35667249/accessing-redux-state-in-an-action-creator
// https://daveceddia.com/access-redux-store-outside-react/
import { store } from "../../redux/configure-store";

import { refreshUser } from "../../user/redux/user-actions";

export const TICK_SINGLE_LIST = "TICK_SINGLE_LIST";
export const TICK_MULTIPLE_LISTS = "TICK_MULTIPLE_LISTS";
export const LIST_CREATE_START = "LIST_CREATE_START";
export const LIST_CREATE_SUCCESS = "LIST_CREATE_SUCCESS";
export const LIST_CREATE_FAIL = "LIST_CREATE_FAIL";
export const LISTS_FETCH_START = "LISTS_FETCH_START";
export const LISTS_FETCH_SUCCESS = "LISTS_FETCH_SUCCESS";
export const LISTS_FETCH_FAIL = "LISTS_FETCH_FAIL";
export const LIST_UPDATE_START = "LIST_UPDATE_START";
export const LIST_UPDATE_SUCCESS = "LIST_UPDATE_SUCCESS";
export const LIST_UPDATE_FAIL = "LIST_UPDATE_FAIL";
export const LISTS_DELETE_START = "LISTS_DELETE_START";
export const LISTS_DELETE_SUCCESS = "LISTS_DELETE_SUCCESS";
export const LISTS_DELETE_FAIL = "LISTS_DELETE_FAIL";
export const LIST_SPLIT_START = "LIST_SPLIT_START";
export const LIST_SPLIT_SUCCESS = "LIST_SPLIT_SUCCESS";
export const LIST_SPLIT_FAIL = "LIST_SPLIT_FAIL";
export const LIKELY_ABORT_OPERATION_START = "LIKELY_ABORT_OPERATION_START";
export const LIKELY_ABORT_OPERATION_SUCCESS = "LIKELY_ABORT_OPERATION_SUCCESS";
export const LIKELY_ABORT_OPERATION_FAIL = "LIKELY_ABORT_OPERATION_FAIL";
export const LIKELY_COMPLETE_OPERATION_START =
  "LIKELY_COMPLETE_OPERATION_START";
export const LIKELY_COMPLETE_OPERATION_SUCCESS =
  "LIKELY_COMPLETE_OPERATION_SUCCESS";
export const LIKELY_COMPLETE_OPERATION_FAIL = "LIKELY_COMPLETE_OPERATION_FAIL";
export const LIKELY_DBREFRESH_ESTIMATECOST_START =
  "LIKELY_DBREFRESH_ESTIMATECOST_START";
export const LIKELY_DBREFRESH_ESTIMATECOST_SUCCESS =
  "LIKELY_DBREFRESH_ESTIMATECOST_SUCCESS";
export const LIKELY_DBREFRESH_ESTIMATECOST_FAIL =
  "LIKELY_DBREFRESH_ESTIMATECOST_FAIL";
export const LIKELY_DBREFRESH_PAYANDPROCESS_START =
  "LIKELY_DBREFRESH_PAYANDPROCESS_START";
export const LIKELY_DBREFRESH_PAYANDPROCESS_SUCCESS =
  "LIKELY_DBREFRESH_PAYANDPROCESS_SUCCESS";
export const LIKELY_DBREFRESH_PAYANDPROCESS_FAIL =
  "LIKELY_DBREFRESH_PAYANDPROCESS_FAIL";
export const LIKELY_SKIPTRACE_ESTIMATECOST_START =
  "LIKELY_SKIPTRACE_ESTIMATECOST_START";
export const LIKELY_SKIPTRACE_ESTIMATECOST_SUCCESS =
  "LIKELY_SKIPTRACE_ESTIMATECOST_SUCCESS";
export const LIKELY_SKIPTRACE_ESTIMATECOST_FAIL =
  "LIKELY_SKIPTRACE_ESTIMATECOST_FAIL";
export const LIKELY_SKIPTRACE_PAYANDPROCESS_START =
  "LIKELY_SKIPTRACE_PAYANDPROCESS_START";
export const LIKELY_SKIPTRACE_PAYANDPROCESS_SUCCESS =
  "LIKELY_SKIPTRACE_PAYANDPROCESS_SUCCESS";
export const LIKELY_SKIPTRACE_PAYANDPROCESS_FAIL =
  "LIKELY_SKIPTRACE_PAYANDPROCESS_FAIL";

export const likelyAiCompleteOperation = (
  listId: number,
  operation: "dbrefresh" | "skiptrace"
) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: LIKELY_COMPLETE_OPERATION_START,
      });

      const { updatedList, updatedLeads } =
        await api.lists.likelyAiCompleteOperation(listId, operation);

      dispatch({
        type: LIKELY_COMPLETE_OPERATION_SUCCESS,
        payload: {
          updatedList,
          updatedLeads,
        },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: LIKELY_COMPLETE_OPERATION_FAIL,
      });
      return false;
    }
  };
};

export const likelyAiAbortOperation = (
  listId: number,
  operation: "dbrefresh" | "skiptrace"
) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: LIKELY_ABORT_OPERATION_START,
      });

      const { updatedList } = await api.lists.likelyAiAbortOperation(
        listId,
        operation
      );

      dispatch({
        type: LIKELY_ABORT_OPERATION_SUCCESS,
        payload: { updatedList },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: LIKELY_ABORT_OPERATION_FAIL,
      });
      return false;
    }
  };
};

export const likelyDBRefreshEstimateCost = (listId: number) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: LIKELY_DBREFRESH_ESTIMATECOST_START,
      });

      const { updatedList } = await api.lists.likelyDBRefreshEstimateCost(
        listId
      );

      dispatch({
        type: LIKELY_DBREFRESH_ESTIMATECOST_SUCCESS,
        payload: { updatedList },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: LIKELY_DBREFRESH_ESTIMATECOST_FAIL,
      });
      return false;
    }
  };
};

export const likelyDBRefreshPayAndProcess = (listId: number) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: LIKELY_DBREFRESH_PAYANDPROCESS_START,
      });

      const { updatedList } = await api.lists.likelyDBRefreshPayAndProcess(
        listId
      );

      // if (isPaid) {
      //   refreshUser()(dispatch);
      //   await fetchLists()(dispatch);
      // }

      dispatch({
        type: LIKELY_DBREFRESH_PAYANDPROCESS_SUCCESS,
        payload: { updatedList },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: LIKELY_DBREFRESH_PAYANDPROCESS_FAIL,
      });
      return false;
    }
  };
};

export const likelySkipTraceEstimateCost = (listId: number) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: LIKELY_SKIPTRACE_ESTIMATECOST_START,
      });

      const { updatedList } = await api.lists.likelySkipTraceEstimateCost(
        listId
      );

      dispatch({
        type: LIKELY_SKIPTRACE_ESTIMATECOST_SUCCESS,
        payload: { updatedList },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: LIKELY_SKIPTRACE_ESTIMATECOST_FAIL,
      });
      return false;
    }
  };
};

export const likelySkipTracePayAndProcess = (listId: number) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: LIKELY_SKIPTRACE_PAYANDPROCESS_START,
      });

      const { updatedList } = await api.lists.likelySkipTracePayAndProcess(
        listId
      );

      dispatch({
        type: LIKELY_SKIPTRACE_PAYANDPROCESS_SUCCESS,
        payload: { updatedList },
      });
      return true;
    } catch (error: any) {
      dispatch({
        type: LIKELY_SKIPTRACE_PAYANDPROCESS_FAIL,
      });
      return false;
    }
  };
};

//

export const splitList = (
  listId: number,
  chunkSize: number,
  prefixName: string,
  prefixDescription: string
) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: LIST_SPLIT_START,
      });

      const res = await api.lists.splitList(
        listId,
        chunkSize,
        prefixName,
        prefixDescription
      );
      dispatch({
        type: LIST_SPLIT_SUCCESS,
        payload: {},
      });
      fetchLists()(dispatch);
      return true;
    } catch (error: any) {
      //console.log(error.response)
      dispatch({
        type: LIST_SPLIT_FAIL,
        payload: error.response.data.message,
      });
      return false;
    }
  };
};

export const fetchLists = () => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: LISTS_FETCH_START,
      });

      let lists = [] as any;
      lists = await api.lists.fetchLists();

      dispatch({
        type: LISTS_FETCH_SUCCESS,
        payload: {
          lists,
        },
      });
      return true;
    } catch (error: any) {
      //console.log(error.response)
      dispatch({
        type: LISTS_FETCH_FAIL,
        payload: error.response.data.message,
      });
      return false;
    }
  };
};

export const createList = (list: ListInterface) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: LIST_CREATE_START,
      });

      const { newList } = await api.lists.createList(list);

      dispatch({
        type: LIST_CREATE_SUCCESS,
        payload: { newList },
      });

      return true;
    } catch (error: any) {
      //console.log(error.response)
      dispatch({
        type: LIST_CREATE_FAIL,
        payload: error.response.data.message,
      });
      return false;
    }
  };
};

export const updateList = (list: ListInterface) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: LIST_UPDATE_START,
      });

      const { updatedList } = await api.lists.updateList(list);

      dispatch({
        type: LIST_UPDATE_SUCCESS,
        payload: { updatedList },
      });

      return true;
    } catch (error: any) {
      //console.log(error.response)
      dispatch({
        type: LIST_UPDATE_FAIL,
        payload: error.response.data.message,
      });
      return false;
    }
  };
};

export const deleteLists = (listIds: number[]) => {
  if (!listIds || !listIds.length) {
    return false;
  }
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: LISTS_DELETE_START,
      });
      const res = await api.lists.deleteLists(
        listIds.filter((id: any) => id !== 0)
      );
      dispatch({
        type: LISTS_DELETE_SUCCESS,
        payload: {
          listIds,
        },
      });
      //this is dependant on it, so it should be updated
      fetchCampaigns()(dispatch);
      return true;
    } catch (error: any) {
      //console.log(error.response)
      dispatch({
        type: LISTS_DELETE_FAIL,
        payload: error.response.data.message,
      });
      return false;
    }
  };
};

export const tickSingleList = (listId: number) => {
  return (dispatch: Function) => {
    dispatch({
      type: TICK_SINGLE_LIST,
      payload: {
        listId,
      },
    });
  };
};

export const tickMultipleLists = (listIds: number[]) => {
  return (dispatch: Function) => {
    dispatch({
      type: TICK_MULTIPLE_LISTS,
      payload: {
        listIds,
      },
    });
  };
};

export const navigateToListsManager = () => (dispatch: Function) =>
  dispatch(push(routePaths.lists.root));

export const navigateToCreateListPage = () => (dispatch: Function) =>
  dispatch(push(routePaths.lists.create));

export const navigateToViewListPage = (id: number) => (dispatch: Function) =>
  dispatch(push(setPathId(routePaths.lists.view, id)));
