import React, { useEffect } from "react";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";

import { StoreState } from "./redux/configure-store";
import { initializeHttpService } from "./api/http-service";
import { routePaths } from "./routing/utils-routing-paths";
import { authenticateUserWithSessionCookie } from "./user/redux/user-actions";
import { initializeLanguage } from "./user/redux/user-actions";

// import HOCRoutePublic from "./routing/hoc-route-public";
// import HOCRoutePrivate from "./routing/hoc-route-user";
// import HOCRouteAdmin from "./routing/hoc-route-admin";
// import LoginPage from "./user/user-public/login-page";
// import UserRegisterPage from "./user/user-public/user-register-page";
// import EnrollPlanPage from "./user/user-public/plan-gopro-page";
// import MainRouterAdmin from "./routing/main-router-admin";
// import MainRouterUser from "./routing/main-router-user";

const HOCRoutePublic = React.lazy(() => import("./routing/hoc-route-public"));
const HOCRoutePrivate = React.lazy(() => import("./routing/hoc-route-private"));
const HOCRouteAdmin = React.lazy(() => import("./routing/hoc-route-admin"));
const HOCRouteSuperUser = React.lazy(
  () => import("./routing/hoc-route-super-user")
);
const LoginPage = React.lazy(() => import("./user/user-public/login-page"));
const UserRegisterPage = React.lazy(
  () => import("./user/user-public/user-register-page")
);
const EnrollPlanPage = React.lazy(
  () => import("./user/user-public/enroll-plan-page")
);
const EnrollKlevrNationPage = React.lazy(
  () => import("./user/user-public/enroll-klevr-nation-page")
);
const SocialUserPage = React.lazy(
  () => import("./user/user-public/social-user-page")
);
const SocialZipCodePage = React.lazy(
  () => import("./user/user-public/social-zipcode-page")
);

const MainRouterAdmin = React.lazy(() => import("./routing/main-router-admin"));
const MainRouterSuperUser = React.lazy(
  () => import("./routing/main-router-super-user")
);
const MainRouterUser = React.lazy(() => import("./routing/main-router-user"));

const loading = () => <div>Loading...</div>;

const App = (props: any) => {
  const { t } = props;

  useEffect(() => {
    // componentWillMount
    const initializeHttpService = async () => {
      await props.initializeHttpService();
    };

    initializeHttpService();
    props.initializeLanguage();

    return () => {};
  }, []); // componentWillUnmount

  return (
    <React.Suspense fallback={loading()}>
      <Switch>
        {/* These routes are common for public and authenticated user */}
        <Route
          exact
          path={routePaths.public.klevrNationZipCode}
          render={(props) => <SocialZipCodePage t={t} {...props} />}
        />
        {/* <Route
          exact
          path={routePaths.public.klevrNationZipCode}
          render={(props) => <SocialZipCodePage2 t={t} {...props} />}
        /> */}
        <Route
          exact
          path={routePaths.public.klevrNationUser}
          render={(props) => <SocialUserPage t={t} {...props} />}
        />
        {/* Registration Flow */}
        <HOCRoutePublic
          t={t}
          path={routePaths.public.klevrNation}
          redirectPath={routePaths.zipcodes.root}
          component={EnrollKlevrNationPage}
        />
        <HOCRoutePublic
          t={t}
          path={routePaths.public.registerWithType}
          redirectPath={routePaths.user.orderPlan}
          component={UserRegisterPage}
        />
        <HOCRoutePublic
          t={t}
          path={routePaths.public.register}
          redirectPath={routePaths.user.orderPlan}
          component={EnrollPlanPage}
        />
        <HOCRoutePublic
          t={t}
          path={routePaths.public.login}
          redirectPath={routePaths.user.dashboard}
          component={LoginPage}
        />
        {/* ----- */}
        <HOCRouteAdmin
          t={t}
          path={routePaths.admin.root}
          redirectPath={routePaths.public.login}
          component={MainRouterAdmin}
        />
        <HOCRouteSuperUser
          t={t}
          path={routePaths.superuser.root}
          redirectPath={routePaths.public.login}
          component={MainRouterSuperUser}
        />
        <HOCRoutePrivate
          // polling timers are here
          t={t}
          path={routePaths.root}
          redirectPath={routePaths.public.login}
          component={MainRouterUser}
        />
      </Switch>
    </React.Suspense>
  );
};

const mapStateToProps = (storeState: StoreState) => ({
  user: storeState.user.user,
});

export default connect(mapStateToProps, {
  initializeHttpService,
  initializeLanguage,
  authenticateUserWithSessionCookie,
})(translate()(App as any)) as any;
