export interface ListInterface {
  id?: number;
  name: string;
  description: string;
  leadIds: number[];
  info?: any;
  likelyAiDBRefreshId?: string;
  likelyAiSkipTraceId?: string;
  likelyAiDBRefreshStatus?:
    | null
    | "estimateRequested"
    | "estimateReady"
    | "likelyAiProcessing"
    | "finalized"
    | "error";
  likelyAiSkipTraceStatus?:
    | null
    // | "estimateRequested"
    | "estimateReady"
    | "likelyAiProcessing"
    | "finalized"
    | "error";
}

export const voidList: ListInterface = {
  id: 0,
  name: "- select list -",
  description: "- no list selected -",
  leadIds: [],
};
