// Klevr Leads Assets
export const pathToLogoPartial = `${process.env.PUBLIC_URL}/assets/klevr-logo-fox-partial.svg`;
export const pathToLogoComplete = `${process.env.PUBLIC_URL}/assets/klevr-logo-fox-complete.svg`;
export const pathToLogoCompleteWhite = `${process.env.PUBLIC_URL}/assets/klevr-logo-green-white.svg`;
export const pathToLogoGreenBug = `${process.env.PUBLIC_URL}/assets/klevr-fox-bug-green.svg`;
export const pathToAvatar = `${process.env.PUBLIC_URL}/assets/klevr-avatar-fox.svg`;
export const pathToMapMarker = `${process.env.PUBLIC_URL}/assets/klevr-map-marker.svg`;
export const pathToBackgroundKlevrFoxBlue = `${process.env.PUBLIC_URL}/assets/background-klevrleads-fox-blue.svg`;

// Logos
export const pathToBlankLogo = `${process.env.PUBLIC_URL}/assets/logo-blank.svg`;
export const pathToLogoStripe = `${process.env.PUBLIC_URL}/assets/logo-stripe-blurple.svg`;
export const pathToLogoTwilio = `${process.env.PUBLIC_URL}/assets/logo-twilio-red.svg`;
export const pathToLogoWebflow = `${process.env.PUBLIC_URL}/assets/logo-webflow-blue.svg`;
export const pathToLogoPostcardmania = `${process.env.PUBLIC_URL}/assets/logo-postcardmania.svg`;
export const pathToLogoSendGrid = `${process.env.PUBLIC_URL}/assets/logo-sendgrid.svg`;
export const pathToLogoLikelyAiDBrefresh = `${process.env.PUBLIC_URL}/assets/logo-likely-ai-dbrefresh.svg`;
export const pathToLogoLikelyAiSkiptrace = `${process.env.PUBLIC_URL}/assets/logo-likely-ai-skiptrace.svg`;
export const pathToLogoPerkPlans = `${process.env.PUBLIC_URL}/assets/logo-perkplans.png`;

// Icons
export const pathToIconFacebook = `${process.env.PUBLIC_URL}/assets/icon-facebook.svg`;
export const pathToIconInstagram = `${process.env.PUBLIC_URL}/assets/icon-instagram.svg`;
export const pathToIconLinkedin = `${process.env.PUBLIC_URL}/assets/icon-linkedin.svg`;
export const pathToIconYoutube = `${process.env.PUBLIC_URL}/assets/icon-youtube.svg`;
export const pathToIconGoogleMyBusiness = `${process.env.PUBLIC_URL}/assets/icon-google-my-business.svg`;

export const pathToIconLikelyAi = `${process.env.PUBLIC_URL}/assets/icon-likely-ai.svg`;
export const pathToIconLikelyAi_BW = `${process.env.PUBLIC_URL}/assets/icon-likely-ai-bw.svg`;

// Landing Page templates
export const pathToTemplateOne = `${process.env.PUBLIC_URL}/assets/template-1.png`;
export const pathToTemplateTwo = `${process.env.PUBLIC_URL}/assets/template-2.png`;
export const pathToTemplateThree = `${process.env.PUBLIC_URL}/assets/template-3.png`;
export const pathToTemplateFour = `${process.env.PUBLIC_URL}/assets/template-4.png`;
export const pathToTemplateFive = `${process.env.PUBLIC_URL}/assets/template-5.png`;

// CSV import template
export const pathToBulkImportLeadsTemplate = `${process.env.PUBLIC_URL}/assets/klevr-bulk-import-leads-template.csv`;

// Other Assets
export const pathToCarouselPostcards = `${process.env.PUBLIC_URL}/assets/carousel-postcard.png`;
export const pathToCarouselOTT = `${process.env.PUBLIC_URL}/assets/carousel-OTT.png`;
export const pathToIphoneMockup = `${process.env.PUBLIC_URL}/assets/mockup-iphone.png`;
export const pathToUSring = `${process.env.PUBLIC_URL}/assets/us-ring.mp3`;

export const getCartIcon = (cartItem: string, refill?: string) => {
  switch (cartItem) {
    case "accountFirstSetup":
      return `${process.env.PUBLIC_URL}/assets/cart-icons/icon_accountFirstSetup.svg`;
    case "brandCreationFirstSetup":
      return `${process.env.PUBLIC_URL}/assets/cart-icons/icon_accountFirstSetup.svg`;
    case "trackingWebsiteFirstSetup":
      return `${process.env.PUBLIC_URL}/assets/cart-icons/icon_accountFirstSetup.svg`;
    case "plan":
      return pathToLogoGreenBug;
    case "phoneMinutes":
      if (refill === "refill") {
        return `${process.env.PUBLIC_URL}/assets/cart-icons/icon_phoneMinutes_refill.svg`;
      } else {
        return `${process.env.PUBLIC_URL}/assets/cart-icons/icon_phoneMinutes.svg`;
      }
    case "smsMessages":
      if (refill === "refill") {
        return `${process.env.PUBLIC_URL}/assets/cart-icons/icon_smsMessages_refill.svg`;
      } else {
        return `${process.env.PUBLIC_URL}/assets/cart-icons/icon_smsMessages.svg`;
      }
    case "emails":
      if (refill === "refill") {
        return `${process.env.PUBLIC_URL}/assets/cart-icons/icon_emails_refill.svg`;
      } else {
        return `${process.env.PUBLIC_URL}/assets/cart-icons/icon_emails.svg`;
      }
    case "klevrCredits":
      return `${process.env.PUBLIC_URL}/assets/cart-icons/icon_klevrCredits.svg`;
    case "farmSize":
      return `${process.env.PUBLIC_URL}/assets/cart-icons/icon_farmSize_orange.svg`;
    case "coachingHours":
      return `${process.env.PUBLIC_URL}/assets/cart-icons/icon_coachingHours.svg`;
    case "socialMediaManagement":
      return `${process.env.PUBLIC_URL}/assets/cart-icons/icon_socialMediaManagement.svg`;
    case "trackingWebsite":
      return `${process.env.PUBLIC_URL}/assets/cart-icons/icon_advancedtrackingwebsite.svg`;
    case "advertising":
      return `${process.env.PUBLIC_URL}/assets/cart-icons/icon_advertising.svg`;
    case "virtualAssistant":
      return `${process.env.PUBLIC_URL}/assets/cart-icons/icon_virtualAssistant.svg`;
    case "zipCodeLicense":
      return `${process.env.PUBLIC_URL}/assets/cart-icons/icon_zipCodeLicense.svg`;
    default:
      break;
  }
};

// Possible credit card brands:
// https://stripe.com/docs/api/payment_methods/object#payment_method_object-card
export const getCreditCardIcon = (brand: string) => {
  switch (brand) {
    case "amex":
      return `${process.env.PUBLIC_URL}/assets/credit-card-icons/amex.png`;
    case "diners":
      return `${process.env.PUBLIC_URL}/assets/credit-card-icons/dinersclub.png`;
    case "discover":
      return `${process.env.PUBLIC_URL}/assets/credit-card-icons/discover.png`;
    case "jcb":
      return `${process.env.PUBLIC_URL}/assets/credit-card-icons/jcb.png`;
    case "mastercard":
      return `${process.env.PUBLIC_URL}/assets/credit-card-icons/mastercard.png`;
    case "unionpay":
      return `${process.env.PUBLIC_URL}/assets/credit-card-icons/discover.png`;
    case "visa":
      return `${process.env.PUBLIC_URL}/assets/credit-card-icons/visa.png`;
    case "unknown":
      return `${process.env.PUBLIC_URL}/assets/credit-card-icons/discover.png`;
    default:
      return `${process.env.PUBLIC_URL}/assets/credit-card-icons/discover.png`;
  }
};
