import { push } from "connected-react-router";

import api from "../../api/api";
import { routePaths } from "../../routing/utils-routing-paths";
import { getLanguage, setLanguage } from "../../theme/translations";

import { UserInterface } from "../../common-interfaces/user";
import { FilePurpose } from "../../common-interfaces/interfaces";

import { navigateToAdminUsersManagerPage } from "../../admin/redux/admin-actions";
import { navigateToSuperUserRelocationsPage } from "../../superuser/redux/superuser-actions";
import { navigateToZipCodesLearnMore } from "../../zipcodes/redux/zipcodes-actions";
import {
  resetCart,
  putAccountFirstSetupIntoCart,
  putBrandCreationFirstSetupIntoCart,
  putPlanIntoCart,
  putSubscriptionAddonsIntoCart,
  navigateToRedirectBeforeCheckoutPage,
  navigateToOrderPlanPage,
  navigateToBillingPage,
} from "../../payments/redux/payments-actions";

export const LOGOUT = "LOGOUT";
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const SWITCH_MENU_DRAWER = "SWITCH_MENU_DRAWER";
//
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const FILE_UPLOAD_START = "FILE_UPLOAD_START";
export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS";
export const FILE_UPLOAD_FAIL = "FILE_UPLOAD_FAIL";
export const USER_CREATE_START = "USER_CREATE_START";
export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS";
export const USER_CREATE_FAIL = "USER_CREATE_FAIL";
export const USER_UPDATE_START = "USER_UPDATE_START";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const USER_UPDATE_ALIAS_FOR_SENDGRID_START =
  " USER_UPDATE_ALIAS_FOR_SENDGRID_START";
export const USER_UPDATE_ALIAS_FOR_SENDGRID_SUCCESS =
  " USER_UPDATE_ALIAS_FOR_SENDGRID_SUCCESS";
export const USER_UPDATE_ALIAS_FOR_SENDGRID_FAIL =
  " USER_UPDATE_ALIAS_FOR_SENDGRID_FAIL";
export const USER_REFRESH_START = "USER_REFRESH_START";
export const USER_REFRESH_SUCCESS = "USER_REFRESH_SUCCESS";
export const USER_REFRESH_FAIL = "USER_REFRESH_FAIL";
export const USER_PASSWORD_CHANGE_START = "USER_PASSWORD_CHANGE_START";
export const USER_PASSWORD_CHANGE_SUCCESS = "USER_PASSWORD_CHANGE_SUCCESS";
export const USER_PASSWORD_CHANGE_FAIL = "USER_PASSWORD_CHANGE_FAIL";
export const RETRIEVE_SOCIAL_DATA_START = "RETRIEVE_SOCIAL_DATA_START";
export const RETRIEVE_SOCIAL_DATA_SUCCESS = "RETRIEVE_SOCIAL_DATA_SUCCESS";
export const RETRIEVE_SOCIAL_DATA_FAIL = "RETRIEVE_SOCIAL_DATA_FAIL";
export const TRANSACTIONS_FETCH_START = "TRANSACTIONS_FETCH_START";
export const TRANSACTIONS_FETCH_SUCCESS = "TRANSACTIONS_FETCH_SUCCESS";
export const TRANSACTIONS_FETCH_FAIL = "TRANSACTIONS_FETCH_FAIL";

export const updateUserAliasForSendGrid = (
  aliasName: string,
  action: "create" | "makePrimary" | "delete"
) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: USER_UPDATE_ALIAS_FOR_SENDGRID_START,
      });

      const { userUpdated } = await api.users.updateUserAliasForSendGrid(
        aliasName,
        action
      );

      dispatch({
        type: USER_UPDATE_ALIAS_FOR_SENDGRID_SUCCESS,
        payload: {
          user: userUpdated,
        },
      });
      return { isSuccess: true, error: "" };
    } catch (error: any) {
      dispatch({
        type: USER_UPDATE_ALIAS_FOR_SENDGRID_FAIL,
        payload: {
          errorMessage:
            error.response.data || "Error in updateUserAliasForSendGrid()",
        },
      });

      return { isSuccess: false, error: error.response.data };
    }
  };
};

export const retrievePublicProfileData = (
  searchedProfileIdentifier: string,
  askingUserId?: number | null
) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: RETRIEVE_SOCIAL_DATA_START,
      });

      const { searchedProfileShowableInfos, ownedZipCodes } =
        await api.users.retrievePublicProfileData(
          searchedProfileIdentifier,
          askingUserId
        );

      dispatch({
        type: RETRIEVE_SOCIAL_DATA_SUCCESS,
        payload: {},
      });

      return { searchedProfileShowableInfos, ownedZipCodes };
    } catch (error: any) {
      dispatch({
        type: RETRIEVE_SOCIAL_DATA_FAIL,
        payload: {},
      });
      return false;
    }
  };
};

export const updateUserSettings = (
  userAttributesEdited: Partial<UserInterface>
) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: USER_UPDATE_START,
      });

      const res = await api.users.updateUserSettings(userAttributesEdited);
      const { userUpdated } = res;

      dispatch({
        type: USER_UPDATE_SUCCESS,
        payload: {
          user: userUpdated,
        },
      });
    } catch (error: any) {
      dispatch({
        type: USER_UPDATE_FAIL,
      });
    }
  };
};

export const refreshUser = () => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: USER_REFRESH_START,
      });

      const refreshedUser = await api.users.fetchUser();

      dispatch({
        type: USER_REFRESH_SUCCESS,
        payload: { user: refreshedUser },
      });

      dispatch({
        type: TRANSACTIONS_FETCH_START,
      });

      const { transactions } = await api.users.fetchTransactionsForUser();

      dispatch({
        type: TRANSACTIONS_FETCH_SUCCESS,
        payload: { transactions },
      });

      return refreshedUser;
    } catch (error: any) {
      dispatch({
        type: USER_REFRESH_FAIL,
      });
      dispatch({
        type: TRANSACTIONS_FETCH_FAIL,
      });
      return null;
    }
  };
};

export const changeUserPassword = (
  currentPassword: string,
  newPassword: string
) => {
  return async (dispatch: Function) => {
    try {
      dispatch({ type: USER_PASSWORD_CHANGE_START });

      await api.users.changeUserPassword(currentPassword, newPassword);

      dispatch({ type: USER_PASSWORD_CHANGE_SUCCESS });

      refreshUser()(dispatch);
      return true;
    } catch (error: any) {
      dispatch({ type: USER_PASSWORD_CHANGE_FAIL });
      return false;
    }
  };
};

export const authenticateUserWithSessionCookie = () => {
  // WARNING: this is not used anymore and may be deleted
  return async (dispatch: Function) => {
    try {
      dispatch({ type: LOGIN_START });
      const user = await api.users.authenticateUserWithSessionCookie();
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user,
        },
      });
      return true;
    } catch (error: any) {
      dispatch({ type: LOGOUT });
      return false;
    }
  };
};

export const loginUser = (emailAddress: string, password: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch({ type: LOGIN_START });
      const user = await api.users.login(emailAddress, password);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user,
        },
      });

      if (user.isAdmin) {
        navigateToAdminUsersManagerPage()(dispatch);
        return;
      }

      if (user.isSuperUser) {
        navigateToSuperUserRelocationsPage()(dispatch);
        return;
      }

      navigateToDashboardPage()(dispatch);
      return true;
    } catch (error: any) {
      //console.log(error.response)
      dispatch({ type: LOGIN_FAIL });
      return false;
    }
  };
};

export const logout = () => {
  return async (dispatch: Function) => {
    try {
      await api.users.logout();
      dispatch({ type: LOGOUT });
      return true;
    } catch (error: any) {
      dispatch(push(routePaths.public.login));
      return false;
    }
  };
};

export const submitRegisterFormForUser = (
  prospectUser: Partial<UserInterface>,
  selectedPlan: any,
  registrationType: string
) => {
  return async (dispatch: Function) => {
    try {
      dispatch({ type: USER_CREATE_START });

      const user = await api.users.register(
        //WARNING: type checks are needed here
        prospectUser.emailAddress || "",
        prospectUser.password || "",
        prospectUser.firstName || "",
        prospectUser.lastName || "",
        prospectUser.appEmailUsername || "",
        prospectUser.phoneNumber || "",
        prospectUser.companyName || "",
        prospectUser.streetAddress || "",
        prospectUser.city || "",
        prospectUser.state || "",
        prospectUser.zipCode || "",
        prospectUser.country || "US",
        prospectUser.license || "",
        prospectUser.noteText || ""
      );

      dispatch({ type: USER_CREATE_SUCCESS, payload: { user } });

      if (user) {
        //if user is purchasing a plan he's redirected to purchasing
        if (
          selectedPlan.tier === "tier0.1" ||
          selectedPlan.tier === "tier0.2" ||
          selectedPlan.tier === "tier0.3" ||
          selectedPlan.tier === "tier1" ||
          selectedPlan.tier === "tier4"
        ) {
          resetCart()(dispatch);
          if (!user.hasPaidAccountSetup) {
            putAccountFirstSetupIntoCart()(dispatch);
          }
          putPlanIntoCart(selectedPlan.tier)(dispatch);
          navigateToRedirectBeforeCheckoutPage()(dispatch);

          return true;
        }

        if (
          selectedPlan.tier === "tier5" ||
          selectedPlan.tier === "tier6" ||
          selectedPlan.tier === "tier7" ||
          selectedPlan.tier === "tier8"
        ) {
          resetCart()(dispatch);

          if (!user.hasPaidBrandSetup) {
            putBrandCreationFirstSetupIntoCart()(dispatch);
          }
          putPlanIntoCart(selectedPlan.tier)(dispatch);
          navigateToRedirectBeforeCheckoutPage()(dispatch);

          return true;
        }

        if (registrationType === "perkplans") {
          navigateToBillingPage()(dispatch);
          return true;
        }

        if (registrationType === "klevr-nation") {
          navigateToZipCodesLearnMore()(dispatch);
          return true;
        }

        if (registrationType === "virtual-assistant") {
          resetCart()(dispatch);

          if (!user.stripeSubscriptionIdForVirtualAssistant) {
            putSubscriptionAddonsIntoCart({
              virtualAssistantRefillQuantity: 1,
            })(dispatch);
            navigateToRedirectBeforeCheckoutPage()(dispatch);
          } else {
            navigateToBillingPage()(dispatch);
          }

          return true;
        }

        // tier0
        navigateToOrderPlanPage()(dispatch);
      }

      return true;
    } catch (error: any) {
      const errorMessage = error.response.data.message;

      dispatch({
        type: USER_CREATE_FAIL,
        payload: {
          errorMessage,
        },
      });
      return false;
    }
  };
};

export const uploadFile = (file: File, filePurpose: FilePurpose) => {
  return async (dispatch: Function) => {
    try {
      dispatch({
        type: FILE_UPLOAD_START,
        payload: { filePurpose },
      });

      let res = { completeS3Url: "", postcardmaniaInfo: {} };

      if (!file) {
        res = await api.uploads.fileUploadedPostprocessing("", filePurpose);
      } else {
        const sanitizedFileName = `${new Date()
          .getTime()
          .toString()}-${file.name.trim().split(" ").join("_")}`; //timestamped

        const { presignedS3Url } = await api.uploads.createPresignedS3Url(
          sanitizedFileName,
          file.type,
          filePurpose
        );

        await api.uploads.uploadFileToS3(presignedS3Url, file);

        res = await api.uploads.fileUploadedPostprocessing(
          sanitizedFileName,
          filePurpose
        );
      }

      if (
        filePurpose === "user-voicemessage" ||
        filePurpose === "user-voicemail" ||
        filePurpose === "user-logo" ||
        filePurpose === "user-profileimage"
      ) {
        await refreshUser()(dispatch);
      }

      dispatch({
        type: FILE_UPLOAD_SUCCESS,
        payload: {
          completeS3Url: res.completeS3Url,
          postcardmaniaInfo: res.postcardmaniaInfo,
        },
      });

      return {
        completeS3Url: res.completeS3Url,
        postcardmaniaInfo: res.postcardmaniaInfo,
      };
      // return true;
    } catch (error: any) {
      dispatch({
        type: FILE_UPLOAD_FAIL,
      });
      return null;
      // return false;
    }
  };
};

// LAYOUT ACTIONS
export const initializeLanguage = () => {
  // get initial language from i18n
  const languageCode = getLanguage();
  return async (dispatch: Function) => {
    dispatch({
      type: CHANGE_LANGUAGE,
      payload: {
        languageCode,
      },
    });
  };
};

export const changeLanguage = (languageCode: string) => {
  return async (dispatch: Function) => {
    await setLanguage(languageCode);

    dispatch({
      type: CHANGE_LANGUAGE,
      payload: {
        languageCode,
      },
    });
  };
};

export const switchMenuDrawer = () => (dispatch: Function) =>
  dispatch({
    type: SWITCH_MENU_DRAWER,
    payload: {},
  });

export const navigateToRegisterPage =
  (registrationPath?: string) => (dispatch: Function) => {
    if (registrationPath) {
      dispatch(
        push(
          routePaths.public.registerWithType.replace(
            "/:registrationType",
            String(registrationPath)
          )
        )
      );
    } else {
      dispatch(push(routePaths.public.register));
    }
  };

export const navigateToLoginPage = () => (dispatch: Function) =>
  dispatch(push(routePaths.public.login));

export const navigateToDashboardPage = () => (dispatch: Function) =>
  dispatch(push(routePaths.user.dashboard));

export const navigateToSettingsPage = () => (dispatch: Function) =>
  dispatch(push(routePaths.user.settings));
